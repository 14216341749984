
export const Theme = {

    colors: {

        palette: {
            primary: { main: '#212121', },
            secondary: {
                light: '#0066ff',
                main: '#7ed957',
            },
            error: {
                main: '#5b1759'
            }
        },
    },
    layout: {
        root: {
            flexGrow: 1,
        },
        tabBar: {
            position: 'static',
            margin: '56px 0 0 0',

        },
        tabBox: {
            justifyContent: 'center',
        },
        title: {
            fontWeight: 'bold',
            padding: '10px',
            textAlign: 'center',
        },
        formControl: {
            padding: '10px',
            width: '100%',
            justifyContent: 'center',
        },
        formControlGrade: {
            marginTop: '',
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: '',
        },
        logButton: {
            padding: '10px'
        },
        box: {
            margin: '100px 0 0 0',
        },
        profileForm: {

        },
        hotkeysForm: {

        },
        field: {
            padding: '10px',
            width: '100%',
            justifyContent: 'center',
        },
        hotkeyField: {
            padding: '10px',
            width: '100%',
            justifyContent: 'center',
        },
        logo: {
            maxWidth: 300,
        },
        boxHome: {
            padding: '100px 0 0 0',
            background: 'linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(41,91,23,1) 50%, rgba(126,217,87,1) 100%)',
        },
        containerTop: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            margin: '50px 0 50px 0'
        },
        titleHome: {
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '10px',
            color: 'white',
        },
        container2: {
            backgroundColor: '#eeeeee',
            padding: '10px',
            justifyContent: 'space-evenly',
            margin: '50px 0 0 0',
            minHeight: '300px',
            maxHeight: '100%',
        },
        sideCards: {
            justifyContent: 'center',
            margin: '25px',
        },
        sideTitle: {
            fontWeight: 'bold',
            textAlign: 'center',
            margin: '50px 0 0 0'
        },
        middleCard: {
            justifyContent: 'center',
            margin: '75px 25px 25px 25px',
        },
        infoBox: {
            padding: '10px',
            minWidth: '300px',
            width: '100%',
        },
        cardGrade: {
            position: 'absolute',
            width: 'fit-content',
            margin: '10px',
            backgroundColor: 'white',
            border: '5px solid #212121',
            padding: '',
            justifyContent: 'center',
        },
        finishGrading: {
            position: 'absolute',
            maxWidth: '95vw',
            maxHeight: '95vh',
            overflowY: 'auto',
            border: '2px solid #000',
            backgroundColor: 'white',
            padding: '20px',
        },
    },
}

