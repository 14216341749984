export const playTypes = [
    {name: 'Play to Basket', value: 'PTB'},
    {name: 'Flagrant 1 Foul', value: 'F1'},
    {name: 'Flagrant 2 Foul', value: 'F2'},
    {name: 'Rebounding', value: 'REB'},
    {name: 'Block', value: 'BLK'},
    {name: 'Player Control Foul', value: 'PCF'},
    {name: 'Team Control Foul', value: 'TCF'},
    {name: 'Illegal Screen', value: 'ILS'},
    {name: 'Ten Second Backcourt Violation', value: 'B10'},
    {name: 'Backcourt Violation', value: 'BCV'},
    {name: 'Handchecking', value: '1014'},
    {name: 'Post Play', value: 'PP'},
    {name: 'Class A Technical Foul', value: 'ATECH'},
    {name: 'Class B Technical Foul', value: 'BTECH'},
    {name: 'Out of Bounds', value: 'OOB'},
    {name: 'Toss', value: 'TOS'},
    {name: 'Other', value: 'OTH'}
]