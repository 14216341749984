export const defaultHotkeyList = {
    'Play/Pause': 
        {
            keyCode: 32,
            name: 'Space'
        },
    'Grade Play': 
        {
            keyCode: 71,
            name:'g'
        },
    'Short Back':
        {
            keyCode: 97,
            name: '1',
            value: -1
        },
    'Short Forward':
        {
            keyCode: 99,
            name: 3,
            value: 1
        },
    'Long Back':
        {
            keyCode: 100,
            name: '4',
            value: -5
        },
    'Long Forward':
        {
            keyCode: 102,
            name: 3,
            value: 5
        }
    
}