import React, {useState} from 'react';
import clsx from 'clsx';
import {Drawer, CssBaseline, AppBar, Toolbar, List, Divider, IconButton, TextField,
        Button, Grid, Box} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TimelineIcon from '@material-ui/icons/Timeline';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CallIcon from '@material-ui/icons/Call';
import GitHubIcon from '@material-ui/icons/GitHub';
import HomeIcon from '@material-ui/icons/Home';
import SportsIcon from '@material-ui/icons/Sports';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import {Link} from 'react-router-dom';
import CustomLink from '../../components/CustomLink'
import Register from '../register'
import {Theme} from '../../styles/Theming'
import {auth} from '../../utilities/fire'


export default function PersistentDrawerLeft(props) {

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showRegister, setShowRegister] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let pageList = [
    {
      commonName: 'Home',
      url: '/',
      icon: <HomeIcon />,
      alwaysShow: true
    },
    {
      commonName: 'My Games',
      url: '/game-grid',
      icon: <SportsIcon />,
      alwaysShow: false
    },
    {
      commonName: 'Analytics',
      url: '/analytics',
      icon: <TimelineIcon />,
      alwaysShow: false
    },
    {
      commonName: 'My Profile',
      url: '/profile',
      icon: <AccountBoxIcon/>,
      alwaysShow: false
    },
    {
      commonName: 'Contact Us',
      url: '/contact-us',
      icon: <CallIcon />,
      alwaysShow: true
    },
    
  ]

  if (props.userData) {
    if (props.userData.isAdmin) {
      pageList.push(
        {
          commonName: 'Site Administration',
          url: '/site-admin',
          icon: <SupervisorAccountIcon/>
        }
      )
      pageList.push(
        {
          commonName: 'SBM GitHub',
          url: 'https://github.com/clive-h-townsend/ref-tech-by-sbm',
          icon: <GitHubIcon />,
          alwaysShow: false
        }
      )
    }
    if (props.userData.approvedEvaluationLevels) {
      if (props.userData.approvedEvaluationLevels.length > 0) {
        pageList.push(

          {
            commonName: 'Claim Games',
            url: '/claim-games',
            icon: <LocalAtmIcon />
          }
        )
      }
    }
  }

  function handleSignIn() {
    auth.signInWithEmailAndPassword(email, password).then(result => {
      props.setUserData(result.user)
    })
  }


  return (
    <div>
      
        <AppBar
          color="primary"
          position="fixed"
          className="appbar-main"
        >
          <Toolbar>
              <IconButton
                color="secondary"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                >
                <MenuIcon />
              </IconButton>
                  <img src={require('../../images/refereedatalyticsTrans.png')}
                  alt="logo" 
                >
            </img>
          </Toolbar>
        </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        className="custom-drawer"
      >
        <div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
            <List className="link-list">
              {pageList.map((page, index) => (

                <div key={index} onClick={()=>handleDrawerClose()}>
                  <CustomLink page={page} loggedIn={!!props.userData.email} type={'ListItem'} />
                </div>
                
              ))}
              <br></br>
              {!!props.userData.email ? 
                  <Link to="/">
                    <div className="forms">
                      <Button className="custom-button" color="primary" variant="contained" onClick={()=>auth.signOut()}>
                        Log Out
                      </Button>
                    </div>
                  </Link>
              : 
              <Grid container className="forms" xs={12}>
                <div>
                  {
                    showRegister 
                  ? 
                    <Register setUserData={props.setUserData} showRegister={setShowRegister} closeDrawer={handleDrawerClose}/>
                  : 
                    <div>
                      <TextField className="text-field" variant="outlined" placeholder="Email" size="small" value={email} onChange={(event) => setEmail(event.target.value)} />
                      <TextField className="text-field" variant="outlined" placeholder="Password" type="password" size="small" value={password} onChange={(event) => setPassword(event.target.value)} /> 
                      
                      <Button className="custom-button" color="primary" variant="contained" onClick={()=>handleSignIn()} >Log In</Button>
        
                      <Button className="custom-button" color="secondary" variant="contained" onClick={()=>setShowRegister(true)} >Register</Button>                      
                    </div>
                  }
                </div>
                  
              </Grid>}
            </List>
      </Drawer>
    </div>
  );
}
