import React, {useState, useEffect} from 'react';
import {Grid, Box, Typography, Table, TableRow, TableCell, TableBody, TableHead} from '@material-ui/core';
import MaterialTable from "material-table";
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import UploadGameButton from '../../components/UploadGameButton'
import RequestEvaluationButton from './RequestEvaluationButton'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import {db} from '../../utilities/fire'
import CustomLink from '../../components/CustomLink'
import {refereeLevels} from '../../defaults/refereeLevels'

export default function GameGridTable(props) {

    const [state, setState] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    
    function selectDate(rowData){
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker 
                    value={rowData.value}
                    format="MM/dd/yyyy hh:mm a"
                    onChange={event => rowData.onChange(moment(event).format())}
                    maxDate={Date("01-01-2010T00:00")}
                    showTodayButton
                />
            </MuiPickersUtilsProvider>
        )
    }
    function showDate(rowData) {
        return (
            <div>
                {moment(rowData.datetime).format('MM/DD/YYYY')} {moment(rowData.datetime).format('hh:mm a')}
            </div>
        )
    }
    function FilmButton(rowData) {
        if (!!rowData.filmUpload) {
            const gameURL = '/game/' + rowData.id
            let page = {commonName: 'View', url: gameURL}
            return(
                <CustomLink page={page} loggedIn={true} type="Button"/> 
            )
        } else {
            return <UploadGameButton userData={props.userData} gameData={rowData} updateRow={updateRow} />
        }
    } 
    function filmLevel(rowData) {
        let name = ''
        refereeLevels.forEach(level => {
            if (level.value === rowData.level) {
                name = level.name
            }
        })
        return name
    }
    function showFilmLevels() {   
        let values = {}
        refereeLevels.forEach(level => {
            values[level.value] = level.name
        })
        return values
    }
        
    const columns2 = [
        { 
            title: 'Location', 
            field: 'location' 
        },
        { 
            title: 'Referee', 
            field: 'referee' 
        },
        { 
            title: 'Umpire 1', 
            field: 'u1' 
        },
        { 
            title: 'Umpire 2', 
            field: 'u2' 
        },
    ]
    const columns = [
        { 
            title: 'Home Team', 
            field: 'homeTeam' 
        },
        { 
            title: 'Away Team', 
            field: 'awayTeam' 
        },
        // { 
        //     title: 'Location', 
        //     field: 'location' 
        // },
        { 
            title: 'Date & Time', 
            field: 'datetime', 
            render: rowData => showDate(rowData),
            editComponent: rowData => selectDate(rowData), 
        },
        // { 
        //     title: 'Referee', 
        //     field: 'referee' 
        // },
        // { 
        //     title: 'Umpire 1', 
        //     field: 'u1' 
        // },
        // { 
        //     title: 'Umpire 2', 
        //     field: 'u2' 
        // },
        {
            title: 'Game Level',
            field: 'level',
            lookup: showFilmLevels(),
            render: rowData=>filmLevel(rowData)
        },
        { 
            title: 'Your Film', 
            field: 'filmUpload', 
            render: rowData => FilmButton(rowData),
            editComponent: ()=><div />
        },
        { 
            title: 'Evalution Status', 
            field: 'evalDone', 
            editComponent: ()=><div />,
            render: rowData => EvaluationButton(rowData) 
        }
    ]
    useEffect(()=>{   

        // Load the user's schedule
        db.collection('games').where('officials', 'array-contains', props.userData.uid).get().then(query => {
            let schedule = []
            if (query.size > 0) {
                query.forEach(doc => {
                    let gameData = {...doc.data(), ...{id: doc.id}}
                    schedule.push(gameData)
                })
                setState(schedule)
            }
            setIsLoading(false)
        })
    }, [isLoading, props.userData.uid])       
    function addRow(newData) {
        return new Promise(resolve => {
            setTimeout(() => {
            resolve();
            let data = [...state]
            let newRow = {...newData}
            newRow = {...newRow, ...{officials: [props.userData.uid]}}
            newRow.datetime = String(newRow.datetime)
            db.collection('games').add(newRow).then(result => {
                newRow = {...newRow, ...{id: result.id}}
                data.push(newRow)
                setState(data)
            })
            }, 600);
        })
    }
    function updateRow(newData, oldData, viaUpload) {
        return new Promise(resolve => {
            setTimeout(() => {
            resolve();
            if (oldData) {
                let data = [...state]
                // If the update is not via an Upload
                if (!viaUpload) {
                    db.collection('games').doc(data[data.indexOf(oldData)].id).update(newData)
                }
                data[data.indexOf(oldData)] = newData
                setState(data)
            }
        }, 600);
        })
    }
    function deleteRow(oldData){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
                let data = [...state]
                data.splice(data.indexOf(oldData), 1)
                setState(data)
                db.collection('games').doc(oldData.id).delete()
            }, 600);
            })
    }
    function EvaluationButton(rowData) {
        if (!!rowData.evaluationRequested) {
            return (
                <Typography variant="body2">Under Review</Typography>
            )
        }
        if (!rowData.filmUpload) {
            return <div />
        }
        if (!rowData.evaluationRequested && !!rowData.filmUpload) {

            return (
                <RequestEvaluationButton rowData={rowData} updateRow={updateRow} userData={props.userData}/>
            )
        }
    }

    return (
        
        <Box className="main-wrapper">
            <Grid className="table-holder" container>
                <Grid className="the-table" item xs sm md={8}>
                    <MaterialTable
                        
                        title="Your Game List"
                        columns={columns}
                        data={state}
                        editable={{
                            onRowAdd: newData => addRow(newData),
                            onRowUpdate: (newData, oldData) => updateRow(newData, oldData),
                            onRowDelete: oldData => deleteRow(oldData),
                        }}
                        options={{
                            pageSize: 7,
                            pageSizeOptions: [20,30,50],
                        }}
                        detailPanel={state => {
                            return(

                                <div className="sub-table-container">

                                    <MaterialTable
                                        columns={columns2}
                                        //data={state}
                                        editable={{
                                            onRowAdd: newData => addRow(newData),
                                            onRowUpdate: (newData, oldData) => updateRow(newData, oldData),
                                            onRowDelete: oldData => deleteRow(oldData),
                                        }}
                                        options={{
                                            toolbar: false,
                                            pageSize: 1,
                                            paging: false,
                                            padding: "dense",
                                        }}
                                        />

                                    {/* <Table size="small" className="sub-table">
                                        <TableHead>
                                            
                                            <TableCell className="table-cell">Location</TableCell>
                                            <TableCell className="table-cell">Referee</TableCell>
                                            <TableCell className="table-cell">Umpire 1</TableCell>
                                            <TableCell className="table-cell">Umpire 2</TableCell>

                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                            
                                                <TableCell className="table-cell">{state.location}</TableCell>
                                                <TableCell className="table-cell">{state.referee}</TableCell>
                                                <TableCell className="table-cell">{state.u1}</TableCell>
                                                <TableCell className="table-cell">{state.u2}</TableCell>
                                            
                                            </TableRow>
                                        </TableBody>
                                        
                                    </Table> */}

                                </div>
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}