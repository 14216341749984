import React, { useState, useRef, useEffect, useCallback } from "react";

import {
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
  Radio,
  FormControlLabel,
  Slider,
  Checkbox,
  Typography,
  Stepper,
  Paper,
  Step,
  StepButton,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/core/Checkbox";

import { playTypes as defaultPlayTypes } from "../../../defaults/playTypes";


function NotesDoc(props) {
  const [notes, setNotes] = useState(props.default);

  function updateNotes(value) {
    setNotes(value);
    props.setNotes(value);
  }

  return (
    <TextField
      autoFocus
      value={notes}
      onChange={event => updateNotes(event.target.value)}
      multiline
      variant="outlined"
      rows="6"
      fullWidth
    />
  );
}

export default function GradingPaginator(props) {
  
  console.log(props.editData);

  const getPlayTypeOptions = () => {
    let thePlays = [];

    defaultPlayTypes.forEach(type => {
      thePlays.push({
        title: type.name,
        selected: false,
        value: type.value
      });
    });

    return thePlays;
  };

  let playTypeOptions = getPlayTypeOptions();

  const decisionSet = [
    {
      value: 1,
      label: "N/A"
    },
    {
      value: 2,
      label: "Whistle"
    },
    {
      value: 3,
      label: "Non-call"
    }
  ];

  const GradePlaySteps = [
    "Play Type",
    "Whistle Type",
    "Play Score",
    "Referee Position",
    "Official",
    "Notes"
  ];

  const positions = [
    "Lead",
    "Center",
    "Trail",
    "Lead Transition",
    "Center Transition",
    "Trail Transition"
  ];

  const referees = ["R", "U1", "U2"];

  function createJoinedPlayTypeOptions() {
    let resultOptions = [];

    playTypeOptions.forEach(option => {
      let contains = false;
      let objSelected = {};

      props.editData.playType.forEach(type => {
        if (type.title === option.title && type.selected === true) {
          contains = true;
          objSelected = type;
        }
      });

      if (contains) {
        resultOptions.push(objSelected);
      } else {
        resultOptions.push(option);
      }
    });

    return resultOptions;
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" checked />;

  const initialPlayScore = props.editData ? props.editData.playScore : 3.5;
  const initialPlayTypeOptions = props.editData
    ? createJoinedPlayTypeOptions()
    : playTypeOptions;
  const initialDecisionType = props.editData ? props.editData.decisionType : 2;
  const initialReferee = props.editData ? props.editData.official : "R";
  const initialPosition = props.editData ? props.editData.position : "Center";
  const initialNotes = props.editData ? props.editData.notes : "";
  const initialTime = props.editData ? props.editData.time : props.rawTime;

  const showUpdate = props.editData ? true : false;

  const [playType, setPlayType] = useState(initialPlayTypeOptions);
  const [decisionType, setDecisionType] = useState(initialDecisionType);
  const [playScore, setPlayScore] = useState(initialPlayScore);
  const [playScoreVerbal, setPlayScoreVerbal] = useState(
    lookupPlayScore(initialPlayScore)
  );
  const [referee, setReferee] = useState(initialReferee);
  const [position, setPosition] = useState(initialPosition);
  const [notes, setNotes] = useState(initialNotes);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [skipped, setSkipped] = useState(new Set());
  const playTypeRef = useRef(null);

  useEffect(() => {
    playTypeRef.current.focus();
  }, []);


  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };


  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          GradePlaySteps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    if (activeStep === 0) {
      // Goes through play types until one is selected
      let hasAPlay = playType.some(testPlay => {
        return testPlay.selected;
      });

      if (hasAPlay) {
        handleComplete();
      }
    } else {
      handleComplete();
    }

    setActiveStep(newActiveStep);
  };

  const totalSteps = () => {
    return GradePlaySteps.length;
  };



  const allStepsCompleted = useCallback(shouldForce => {
    if (completedSteps() === totalSteps() - skippedSteps() || !!shouldForce) {
      let playtypeArray = [];

      playType.forEach(type => {
        if (type.selected) {
          playtypeArray.push(type);
        }
      });

      let playResult = {
        time: initialTime,
        decisionType: decisionType,
        playType: playtypeArray,
        grade: playScoreVerbal,
        official: referee,
        playScore: playScore,
        position: position,
        notes: notes
      };

      if (showUpdate) {
        props.updatePlay(playResult, props.editData);
      } else {
        props.addPlay(playResult);
      }
      console.log(playResult);
    }

    return completedSteps() === totalSteps() - skippedSteps();
  }, [completedSteps, decisionType, initialTime, notes, playScore, playScoreVerbal, playType, position, props, referee, showUpdate, skippedSteps, totalSteps])


  const handleKeyPress = useCallback(event => {
    if (event.keyCode === 37) {
      // Left Arrow
      handleBack();
    }
    if (event.keyCode === 39) {
      // Right Arrow
      handleNext();
    }

    if (event.keyCode === 13) {
      allStepsCompleted();
    }

    if (activeStep === 1) {
      if (event.keyCode === 97 || event.keyCode === 49) {
        setDecisionType(1);
      }
      if (event.keyCode === 98 || event.keyCode === 50) {
        setDecisionType(2);
      }
      if (event.keyCode === 99 || event.keyCode === 51) {
        setDecisionType(3);
      }
    }
    if (activeStep === 2) {
      if (event.keyCode === 97 || event.keyCode === 49) {
        setPlayScore(1);
        setPlayScoreVerbal(lookupPlayScore(1));
      }
      if (event.keyCode === 98 || event.keyCode === 50) {
        setPlayScore(2);
        setPlayScoreVerbal(lookupPlayScore(2));
      }
      if (event.keyCode === 99 || event.keyCode === 51) {
        setPlayScore(3);
        setPlayScoreVerbal(lookupPlayScore(3));
      }
      if (event.keyCode === 100 || event.keyCode === 52) {
        setPlayScore(4);
        setPlayScoreVerbal(lookupPlayScore(4));
      }
      if (event.keyCode === 101 || event.keyCode === 53) {
        setPlayScore(5);
        setPlayScoreVerbal(lookupPlayScore(5));
      }
      if (event.keyCode === 102 || event.keyCode === 54) {
        setPlayScore(6);
        setPlayScoreVerbal(lookupPlayScore(6));
      }
    }
    if (activeStep === 3) {
      if (event.keyCode === 97 || event.keyCode === 49) {
        setPosition("Lead");
      }
      if (event.keyCode === 98 || event.keyCode === 50) {
        setPosition("Center");
      }
      if (event.keyCode === 99 || event.keyCode === 51) {
        setPosition("Trail");
      }
      if (event.keyCode === 100 || event.keyCode === 52) {
        setPosition("Lead Transition");
      }
      if (event.keyCode === 101 || event.keyCode === 53) {
        setPosition("Center Transition");
      }
      if (event.keyCode === 102 || event.keyCode === 54) {
        setPosition("Trail Transition");
      }
    }
    if (activeStep === 4) {
      if (event.keyCode === 97 || event.keyCode === 49) {
        setReferee("R");
      }
      if (event.keyCode === 98 || event.keyCode === 50) {
        setReferee("U1");
      }
      if (event.keyCode === 99 || event.keyCode === 51) {
        setReferee("U2");
      }
    }
  }, [activeStep, allStepsCompleted, handleNext, lookupPlayScore]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [handleKeyPress]);


  function GradeStepContent(step) {
    switch (step) {
      case 0:
        // return null;
        return ShowPlayTypes();
      case 1:
        return ShowWhistleFlava();
      // return null;
      case 2:
        // return null;
        return ShowPlayScore();
      case 3:
        // return null;
        return ShowRefereePosition();
      case 4:
        // return null;
        return ShowRefereeOnPlay();
      case 5:
        // return null;
        return <NotesDoc setNotes={setNotes} default={notes} />;
      default:
        return "Unknown current location";
    }
  }

  function ShowRefereeOnPlay() {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Referee</FormLabel>
        <FormGroup>
          {referees.map(refereeItem => {
            return (
              <FormControlLabel
                control={
                  <Radio checked={referee === refereeItem} name={refereeItem} />
                }
                label={refereeItem}
                onClick={() => setReferee(refereeItem)}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }

  function ShowRefereePosition() {
    return (
      <FormControl component="fieldset" >
        <FormLabel component="legend">Referee Position</FormLabel>
        <FormGroup>
          {positions.map(positionItem => {
            return (
              <FormControlLabel
                control={
                  <Radio
                    checked={position === positionItem}
                    name={positionItem}
                  />
                }
                label={positionItem}
                onClick={() => setPosition(positionItem)}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }

  function handleWhistleFlavaChange(v) {
    setDecisionType(v);
  }

  function ShowWhistleFlava() {
    return (
      <div>
        <Typography>Select the type of Decision:</Typography>
        {decisionSet.map(type => {
          return (
            <div onClick={() => handleWhistleFlavaChange(type.value)}>
              <Radio checked={type.value === decisionType} />
              {type.label} - (Hotkey: {type.value})
            </div>
          );
        })}
      </div>
    );

  }

  function ShowPlayTypes() {
    let defaults = [];

    if (playType) {
      playType.forEach(option => {
        if (option.selected) {
          defaults.push(option);
        }
      });
    }
    return (
      <Autocomplete
        ref={playTypeRef}
        tabIndex={-2}
        multiple
        id="play-types-selctor"
        options={playType}
        defaultValue={defaults}
        disableCloseOnSelect
        getOptionLabel={option => option.title}
        onChange={handlePlayTypeChange}
        renderOption={option => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={option.selected}
            />
            {option.title}
          </React.Fragment>
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label="Play Types"
              variant="outlined"
              placeholder="Plays"
            />
          );
        }}
      />
    );
  }

  function handlePlayTypeChange(event, values) {
    let newPlayTypes = playType;

    newPlayTypes.forEach((option, index) => {
      if (values.indexOf(option) > -1) {
        newPlayTypes[index].selected = true;
      } else {
        newPlayTypes[index].selected = false;
      }
    });

    setPlayType(newPlayTypes);
  }

  function GradeScale() {
    let scale = [
      {
        value: 1,
        label: "Incorrect"
      },
      {
        value: 3.5,
        label: "|"
      },
      {
        value: 6,
        label: "Correct"
      }
    ];

    return scale;
  }

  function handlePlayScoreChange(e, v) {
    setPlayScore(v);
    setPlayScoreVerbal(lookupPlayScore());
  }

  function lookupPlayScore(value) {
    let scoreCheck;
    if (!!value) {
      scoreCheck = value;
    } else {
      scoreCheck = playScore;
    }

    if (decisionType === 3) {
      if (scoreCheck < 1) {
        return "Murder & No arrest";
      }
      if (scoreCheck >= 1 && scoreCheck < 2) {
        return "Needs Whistle";
      }
      if (scoreCheck >= 2 && scoreCheck < 3) {
        return "Inconclusive - Likely Non-call Incorrect";
      }
      if (scoreCheck >= 3 && scoreCheck < 4) {
        return "Inconclusive - Likely Non-call Correct";
      }
      if (scoreCheck >= 4 && scoreCheck < 5) {
        return "Technically Correct";
      }
      if (scoreCheck >= 5 && scoreCheck < 6) {
        return "Good Patience";
      }
      if (scoreCheck >= 6) {
        return "Gamer - Withstood Pressure";
      }
    } else if (decisionType === 2) {
      if (scoreCheck < 1) {
        return "Gamer Miss";
      }
      if (scoreCheck >= 1 && scoreCheck < 2) {
        return "Incorrect Call";
      }
      if (scoreCheck >= 2 && scoreCheck < 3) {
        return "Inconclusive - Likely Incorrect Call";
      }
      if (scoreCheck >= 3 && scoreCheck < 4) {
        return "Inconclusive - Likely Correct Call";
      }
      if (scoreCheck >= 4 && scoreCheck < 5) {
        return "Technically Correct";
      }
      if (scoreCheck >= 5 && scoreCheck < 6) {
        return "Correct";
      }
      if (scoreCheck >= 6) {
        return "Gamer - Crew Saver";
      }
    }
  }

  function ShowPlayScore() {
    return (
      <div>
        <Typography id="discrete-slider-custom" gutterBottom>
          Play Score: {playScoreVerbal}
        </Typography>
        <Slider
          defaultValue={3}
          //   aria-labelledby="discrete-slider-custom"
          step={0.05}
          // valueLabelDisplay="auto"
          marks={GradeScale()}
          min={0}
          max={7}
          onChange={handlePlayScoreChange}
          value={playScore}
        />
      </div>
    );
  }

  const isStepOptional = step => {
    return step === 50;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = step => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);


  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  const hideElement = { display: "none" };

  return (
    <Paper className="log-play">
      <div className="log-play-header">
        <div className="log-play-text">
          <Typography variant="body1">
            Log Play (Note: Hotkeys are disabled when Grader is open.)
          </Typography>
        </div>
        <div className="log-play-close">
          <Button onClick={() => props.closeGrader()}>
            <CloseIcon />
          </Button>
        </div>
      </div>

      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {GradePlaySteps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};

          if (isStepOptional(index)) {
            buttonProps.options = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      <div>
        {allStepsCompleted() ? (
          <div>
            <Typography >
              All steps completed. - you're finineshed
            </Typography>
          </div>
        ) : (
          <div>
            <Typography component={"span"} >
              {GradeStepContent(activeStep)}
            </Typography>
            <div className="log-play-navigation">
              <Button
                className="back"
                disabled={activeStep === 0}
                onClick={() => handleBack()}
              >
                Back
              </Button>
              <Button
                className="next"
                variant="contained"
                color="primary"
                onClick={() => handleNext()}
              >
                Next
              </Button>
              
              {isStepOptional(activeStep) && !completed.has(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSkip()}
                >
                  Skip
                </Button>
              )}

              {activeStep !== GradePlaySteps.length &&
                (completed.has(activeStep) ? (
                  <Typography variant="caption">
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleComplete()}
                    style={
                      completedSteps() === totalSteps() - 1 ? null : hideElement
                    }
                  >
                    Save Play
                  </Button>
                ))}

              {showUpdate ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => allStepsCompleted(true)}
                >
                  Update
                </Button>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </Paper>
  );
}
