import React, {useState, useEffect, useCallback, useRef, useImperativeHandle, forwardRef} from 'react'

import ReactPlayer from 'react-player'

import {defaultHotkeyList} from '../../defaults/hotkeys'

const Video = forwardRef((props, videoRef) => {

    const player = useRef(null)
    const url = props.options.src
    // console.log
    const [playStatus, setPlayStatus] = useState(false)
    const [duration, setDuration] = useState(0)

    const handlePlayPause = useCallback((forceValue)=> {

        if (!!forceValue) {
            if (forceValue === 'pause') {
                setPlayStatus(false)
            } else if (forceValue === 'play') {
                setPlayStatus(true)
            }
        } else {

            setPlayStatus(!playStatus)
        }

        props.updateTime(player.current.getCurrentTime())
        
    }, [playStatus, props])

    const handleJump = useCallback((jumpLength) => {

        const currentTime = player.current.getCurrentTime()
        const newTime = currentTime + jumpLength
        
        const newSeekPercentage = newTime / duration
        
        player.current.seekTo(newSeekPercentage)

    }, [duration])

    const handleSetTime = useCallback((seekTime) => {

        const newSeekPercentage = seekTime / duration

        player.current.seekTo(newSeekPercentage)

    }, [duration])

    

    const handleKeyPress = useCallback((event) => {

        // console.log(event.keyCode)
        // console.log(props.userData.hotkeys['Short Forward'].keyCode)
        // console.log(props.checkGraderOpen())

        if (event.keyCode === props.userData.hotkeys['Play/Pause'].keyCode && !props.checkGraderOpen()) {

            handlePlayPause()
            
        }

        if (event.keyCode === props.userData.hotkeys['Short Forward'].keyCode && !props.checkGraderOpen()) {

            handleJump(defaultHotkeyList['Short Forward'].value)

        }
        if (event.keyCode === props.userData.hotkeys['Long Forward'].keyCode && !props.checkGraderOpen()) {

            handleJump(defaultHotkeyList['Long Forward'].value)
            
        }
        if (event.keyCode === props.userData.hotkeys['Short Back'].keyCode && !props.checkGraderOpen()) {

            handleJump(defaultHotkeyList['Short Back'].value)
            
        }
        if (event.keyCode === props.userData.hotkeys['Long Back'].keyCode && !props.checkGraderOpen()) {

            handleJump(defaultHotkeyList['Long Back'].value)
            
        }

        if (event.keyCode === props.userData.hotkeys['Grade Play'].keyCode && !props.checkGraderOpen()) {

            handlePlayPause('pause')
            
        }

    }, [handlePlayPause, handleJump, props])



    useEffect(()=>{
        document.addEventListener("keydown", handleKeyPress, false)
    
        return () => {
          document.removeEventListener("keydown", handleKeyPress, false)
        }
      }, [handleKeyPress])
   

    useImperativeHandle(videoRef, () => {
        return {
            handlePlayPause: handlePlayPause, 
            handleSetTime: handleSetTime
        }
    })

    function handleUpdateProgress(event) {
        props.updateTableStatus(event)
    }

    return (
        <div className="playerWrap">
            <div style={{margin: 'auto', width:'80%'}}>
            <ReactPlayer
                ref={player}
                url={url}   
                playing={playStatus}
                controls={true}
                onDuration={videoDuration => setDuration(videoDuration)}
                progressInterval={1000}
                onProgress={handleUpdateProgress}
                width="100%"
                height="100%"
            />
            </div>
        </div>
    )
})

export default Video;