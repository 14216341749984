import React, { useState } from 'react';
import { Typography, Box, Button, TextField,
     Grid  } 
from '@material-ui/core'
import {auth, db} from '../../utilities/fire'
import {withRouter} from 'react-router-dom'
import {v4} from 'uuid'

import {defaultHotkeyList} from '../../defaults/hotkeys'

function Register(props) {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    const handleRegister = () => {

        let uuidVal = v4()
        uuidVal = uuidVal.substring(0,6).toUpperCase()

        // When they register (while we are at it, need acceptable evaluators)
        auth.createUserWithEmailAndPassword(email, password1).then(result => {
            props.closeDrawer()
            props.setUserData(result.user)
            db.collection('users').doc(result.user.uid).set({
                firstName: firstName,
                lastName: lastName,
                email: email,
                availableHalves: 2,
                phone: '',
                address: '',
                usState: '',
                zip: '',
                uid: result.user.uid,
                hotkeys: defaultHotkeyList,
                acceptableEvaluators: ['NBA', 'MD1'],
                abvalue: Math.random(),
                confirmedEmail: uuidVal
            }).then(result => {
                db.collection('mail').add({
                    to: email,
                    template: {
                        name: 'SignUp',
                        data: {
                            name: firstName,
                            link: 'https://reflytics.com/authenticate/' + uuidVal,
                            code: uuidVal
                        }
                    }
                })
                props.history.push('/authenticate/Enter%20ID%20Code%20Here')
            })
        })

    }

    
        return (
            <Box>
                <Grid container className="forms">
                    <Grid item>
                        <Typography className="forms-title" variant="h5" align="center"><b>Register</b></Typography>        
                        <TextField className="text-field" placeholder="First Name" variant="outlined" 
                        value={firstName} onChange={e => setFirstName(e.target.value)}/>
                        <TextField className="text-field" placeholder="Last Name" variant="outlined" 
                        value={lastName} onChange={e => setLastName(e.target.value)}/>
                        <TextField className="text-field" placeholder="Email" variant="outlined" 
                        type="email" value={email} onChange={e => setEmail(e.target.value)}/>
                        <TextField className="text-field" placeholder="Password" variant="outlined" 
                        type="password" value={password1} onChange={e => setPassword1(e.target.value)}/>
                        <TextField className="text-field" placeholder=" Confirm Password" variant="outlined" 
                        type="password" value={password2} onChange={e => setPassword2(e.target.value)}/>
                        {
                        password1 === password2 &&
                            password1.length > 0 &&
                            email.length > 0 &&
                            firstName.length > 0 &&
                            lastName.length > 0 ?
                                
                                <Button className="custom-button" color="secondary" variant="contained" onClick={()=>handleRegister()} block>Register</Button>
                            :   
                                null
                        }
                        <Button className="custom-button" color="primary" variant="contained" onClick={()=>props.showRegister(false)}>Back to Login</Button>
                    </Grid>
                </Grid>
            </Box>
        );
    
}

export default withRouter(Register);
