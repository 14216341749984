import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'

import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import {db} from '../../utilities/fire'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PaymentDialog(props) {
    const stripe = useStripe();
    const elements = useElements();
  const [open, setOpen] = useState(true);
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false)

  const [hasACard, setHasACard] = useState(false)
  

  useEffect(() => {

    

      db.collection('users').doc(props.userData.uid).collection('sources').get().then(query => {
        if (query.size>0) {
          setHasACard(true)
        } else {
          let unsub = db.collection('users').doc(props.userData.uid).onSnapshot(doc => {

            // Wait until the available halves has been bumped then unsubscribe
            if (doc.data().availableHalves > 2) {
              unsub()
              props.handlePaymentComplete()
            }
          })          
        }
      })
      
  }, [props])


  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setHasClickedSubmit(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    const token = await stripe.createToken(cardElement)

    

    if (error || !token) {
      console.log('[error]', error);
      setHasClickedSubmit(false)
    } else {
        db.collection('users').doc(props.userData.uid).collection('tokens').add(token.token).then(result => {
            db.collection('users').doc(props.userData.uid).update({pendingCharge: 500})
        })
        
      console.log('[PaymentMethod]', paymentMethod);
    }
  };

  const handleChargeMore = async (amount) => {

    setHasClickedSubmit(true)
    // eslint-disable-next-line no-unused-vars
    const settingAmount = await db.collection('users').doc(props.userData.uid).collection('charges').add({
      amount: amount
    })
    const newUserData = await db.collection('users').doc(props.userData.uid).get()
    let updatedVals
    if (props.type === 'uploadGame') {
      let availableHalves = newUserData.data().availableHalves
      let newHalves = availableHalves + 20
      updatedVals = await db.collection('users').doc(props.userData.uid).update({ availableHalves: newHalves })
    } 
    if (props.type === 'requestEval') {
      let availableEvals = newUserData.data().availableEvals ? newUserData.data().availableEvals : 0
      let newEvals = availableEvals + 1
      updatedVals = await db.collection('users').doc(props.userData.uid).update({ availableEvals: newEvals })
    }

    setHasClickedSubmit(false)
    props.handlePaymentComplete()

    return updatedVals

  }

  const newCardDialogContent = () => {

    let prompt = ''

    if (props.type === 'uploadGame') {
      prompt = 'When you upload a game, it costs $5.'
    }
    if (props.type === 'requestEval') {
      prompt = 'When you request an eval it costs, $40.'
    }

    return (

    <DialogContent dividers>
          <Typography gutterBottom>
             {prompt}
          </Typography>

            <form onSubmit={handleSubmit}>
              
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "17px",
                  fontFamily: "Gill Sans, sans-serif",
                  backgroundColor: '#fafffa',
                  color: "#194a19",
                  "::placeholder": {
                    color: "#aab7c4"
                  }                  
                },
                invalid: {
                  color: "#9e2146"
                }
              }
            }}
          />
          {hasClickedSubmit ? <CircularProgress color="secondary" /> : <Button type="submit" disabled={!stripe}>Pay</Button>}
          </form>
          
        </DialogContent>
    )
  }
  const ChargeMoreDialogContent = () => {

    let prompt = ''
    let amount = 100

    if (props.type === 'uploadGame') {
      prompt = 'You seem to be out of game uploads. An additional 10 games costs $4 / month.'
      amount = 400
    }
    if (props.type === 'requestEval') {
      prompt = 'You seem to be out of evaluations. An additional evaluation costs $40.'
      amount = 4000
    }


    return (

    <DialogContent dividers>
          <Typography gutterBottom>
            {prompt}
          </Typography>
          <div style={{margin: 'auto', width: '40%'}}>

            {hasClickedSubmit ? <CircularProgress color="secondary" /> : <Button variant="contained" color="secondary" type="submit" onClick={()=>handleChargeMore(amount)}>Accept Payment</Button>}
          </div>
          
        </DialogContent>
    )
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Referee Datalytics - Prime User
        </DialogTitle>
        {
          hasACard ? 
            ChargeMoreDialogContent()
          : 
            newCardDialogContent()
        }
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Never mind, I'll Pay Later
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export async function permitAction(type, userData) {

    let permitAction
    
    if (type === 'uploadGame') {

        permitAction = await db.collection('users').doc(userData.uid).get().then(doc => {
            if (doc.data().availableHalves > 0) {
                return true
            } else {
                return false
            } 
        })
        
    }

    if (type === 'requestEval') {

      permitAction = await db.collection('users').doc(userData.uid).get().then(doc => {
        if (doc.data().availableEvals > 0) {
          return true
        } else {
          return false
        }
      })
    }

    console.log('Permit? ' + String(permitAction))

    return permitAction
}