import React from 'react';
// import {db, auth} from '../../utilities/fire';

export default function Dashboard() {

    return (
        
            <div className="main-wrapper">
                Dashboard
            </div>
      
    );
}