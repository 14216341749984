import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Box, TextField, Typography, Tab, AppBar, Tabs, Snackbar,
        FormControl, InputLabel, Select, MenuItem, Button, Container,
        FormGroup, Checkbox, FormControlLabel, Grid, 
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import {db, auth} from '../../utilities/fire';
import {defaultHotkeyList} from '../../defaults/hotkeys';
import {statesList} from '../../defaults/statesList';
import {refereeLevels} from '../../defaults/refereeLevels'
import _ from 'lodash' //    <---- This is a really useful thing to use with array.... _ = Low Dash = lodash



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3} ><Container maxWidth="md">{children}</Container></Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function SimpleTabs(props) {
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [usState, setUsState] = useState('AL')
  const [zip, setZip] = useState('')
  const [acceptableEvaluators, setAcceptableEvaluators] = useState([])

  const [hotkeys, setHotkeys] = useState(defaultHotkeyList)

  useEffect(()=>{
    
    db.collection('users').doc(props.userData.uid).get().then(doc => {
      if (!!doc.data().firstName) {
        setFirstName(doc.data().firstName)
      }
      if (!!doc.data().lastName) {
        setLastName(doc.data().lastName)
      }
      if (!!doc.data().email) {
        setEmail(doc.data().email)
      }
      if (!!doc.data().phone) {
        setPhone(doc.data().phone)
      }
      if (!!doc.data().address) {
        setAddress(doc.data().address)
      }
      if (!!doc.data().city) {
        setCity(doc.data().city)
      }
      if (!!doc.data().usState) {
        setUsState(doc.data().usState)
      }
      if (!!doc.data().zip) {
        setZip(doc.data().zip)
      }
      if (!!doc.data().hotkeys) {
        setHotkeys(doc.data().hotkeys)
      }
      if (!!doc.data().acceptableEvaluators) {
        setAcceptableEvaluators(doc.data().acceptableEvaluators)
      }
    })
  }, [props])


  const handleSave = () => {
    db.collection('users').doc(auth.currentUser.uid).set({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      address: address,
      city: city,
      usState: usState,
      zip: zip,
    }, {merge: true})

    setOpen(true);

  }
const [open, setOpen] = useState(false);
const handleClick = () => {
  setOpen(true);
};
const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};
const superClick = () => {
  handleClick();
  handleSave();
}

function registerHotkey(keyFunction, event) {


  if (document.activeElement === document.getElementById(keyFunction)) {

    let newHotkeys = hotkeys
    newHotkeys[keyFunction] = {
      name: event.key === ' ' ? 'Space' : event.key,
      keyCode: event.keyCode
    }
    setHotkeys({...newHotkeys})

    db.collection('users').doc(props.userData.uid).update({
      hotkeys: newHotkeys
    })
    
  }

} 

function checkForLevel(field) {

  let acceptable = _.includes(acceptableEvaluators, field)

  return acceptable

}

function handleCheckUpdate(event, field) {

  let newAcceptables = acceptableEvaluators

  if (event.target.checked) {

    newAcceptables.push(field)
    setAcceptableEvaluators(_.flatten(newAcceptables)) // Flatten removes duplicates

  } else {

    let index = acceptableEvaluators.indexOf(field);
    let finalAcceptables = []
    newAcceptables.forEach((type, cnt) => {
      if (cnt !== index) {
        finalAcceptables.push(type)
      }
      setAcceptableEvaluators(finalAcceptables)
      newAcceptables = finalAcceptables
    })

    // That was way harder than it should have been
  }

  db.collection('users').doc(props.userData.uid).update({
    acceptableEvaluators: newAcceptables
  })

}

  return (
    <div className="main-wrapper">
      
      <AppBar className="appbar">
        <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Profile" icon={''} {...a11yProps(0)} />
          <Tab label="Hotkeys" {...a11yProps(1)} />
          <Tab label="Evaluations" {...a11yProps(2)} />
          <Tab label="Payments" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container className="two-app-bar-container">
          <Grid item xs sm md={8} className="forms">
              <Typography className="forms-title" align="center" variant="h5">Update your profile.</Typography>
              <TextField className="text-field" required variant="outlined" label="First Name" size="small" value={firstName} onChange={(event) => setFirstName(event.target.value)}/><br/>
              <TextField className="text-field" required variant="outlined" label="Last Name" size="small" value={lastName} onChange={(event) => setLastName(event.target.value)}/><br/> 
              <TextField className="text-field" required variant="outlined" label="Email" size="small" type="email" value={email} onChange={(event) => setEmail(event.target.value)}/><br/>
              <TextField className="text-field" required variant="outlined" label="Phone Number" size="small" value={phone} onChange={(event) => setPhone(event.target.value)}/><br/> 
              <TextField className="text-field" required variant="outlined" label="Street Address" size="small" value={address} onChange={(event) => setAddress(event.target.value)}/><br/>
              <TextField className="text-field" required variant="outlined" label="City" size="small" value={city} onChange={(event) => setCity(event.target.value)}/><br/>
              <FormControl className="select-field" >
                  <Select required id="state" labelId="state" variant="outlined" value={usState} onChange={(event) => setUsState(event.target.value)}>
                    {statesList.map(usState => {
                      return(
                        <MenuItem key={usState.abbr} value={usState.abbr}>{usState.name}</MenuItem>
                        );
                    })}
                  </Select>
              </FormControl><br/>
              <TextField required className="text-field" variant="outlined" label="Zip Code" size="small" value={zip} onChange={(event) => setZip(event.target.value)}/><br/>  
              <Button color="primary" variant="contained" type="submit" form="form" onClick={superClick} fullWidth>
                Update
              </Button>
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">Success!</Alert>
              </Snackbar>
          </Grid>
        </Grid>
          
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container className="two-app-bar-container">
          <Grid className="forms" item xs sm md={6}>
              <Typography className="forms-title" align="center" variant="h5">Your video hotkeys.</Typography>
              
              {Object.keys(hotkeys).map(hotkey => {
                return (
                  <div key={hotkey}>
                    <TextField className="text-field" id={hotkey} variant="outlined" label={hotkey} p={2} value={!!hotkeys[hotkey] ? hotkeys[hotkey].name : ''} onKeyDown={event=>registerHotkey(hotkey, event)} />
                  </div>
                )
              })}
              <Button color="primary" variant="contained" type="submit" form="form" onClick={superClick} fullWidth>
                Update
              </Button>
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">Success!</Alert>
              </Snackbar>
            </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid container className="two-app-bar-container">
          <Grid className="forms" item xs sm md={6}>
            <Typography className="forms-title" align="center" variant="h5">
                Select your approved evaluators.
            </Typography><br/>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                {refereeLevels.map(level => {
                  return (
                    <div key={level.name}>
                      <FormControlLabel 
                        value={level.value}
                        control={<Checkbox color="primary" />}
                        label={level.name}
                        labelPlacement="end"
                        checked={checkForLevel(level.value)}
                        onChange={event => handleCheckUpdate(event, level.value)}
                      />
                    </div>
                  )
                })}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Grid container className="two-app-bar-container">
          <Grid className="forms" item xs sm md={6}>
            <Typography className="forms-title" align="center" variant="h5">
                Change your payment method.
            </Typography><br/>
            <FormControl component="fieldset">
              
              
            </FormControl>
          </Grid>
        </Grid>
      </TabPanel>

    </div>
  );
}
