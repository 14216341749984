import React, {useState, useEffect} from 'react';

import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';

import Navigation from './views/navigation'
import Dashboard from './views/dashboard'
import Home from './views/home'
import Profile from './views/profile'
import GameGridTable from './views/Game-Grid'
import Game from './views/Game'
import ClaimGames from './views/Claim-Games'
import SiteAdmin from './views/Site-Admin'
import ContactUs from './views/Contact-Us'
import Analytics from './views/Analytics'
import Authenticate from './views/Authenticate'
import {auth, db} from './utilities/fire'

import {CircularProgress, createMuiTheme, MuiThemeProvider} from '@material-ui/core'

import {Theme} from './styles/Theming'
const theme = createMuiTheme(Theme.colors);

const stripePromise = loadStripe('pk_test_KdVoCmevUUrlxp5eFGTqsu5V00oJ1oZiBp');

export default function App() {

  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {

    // When a user logs in or out
    auth.onAuthStateChanged(user => {

      // If it was a log-in
      if (user) {
        let newUserData

        // Get the user document
        db.collection('users').doc(user.uid).get().then(doc => {

          // If it exists
          if (doc.exists) {
            newUserData = { ...user, ...doc.data() }
            setUserData(newUserData)
            setIsLoading(false)
            setIsLoggedIn(true)
            if (doc.data().confirmedEmail === 'confirmed') {
              setIsAuthenticated(true)
            }
            
          } else {
            setIsLoggedIn(false)
            setIsLoading(false)
            setIsAuthenticated(false)
          }

        })

      } else {
        setUserData({})
        setIsLoading(false)
        setIsLoggedIn(false)
        setIsAuthenticated(false)
      }
    })
  }, [])



  let showClaimGames = false
  let showAdmin = false

  if (!!userData) {
    if (userData.approvedEvaluationLevels) {
      if (userData.approvedEvaluationLevels.length > 0) {
        showClaimGames = true
      }
    }
    if (userData.isAdmin) {
      showAdmin = true
    }
  }


  if (isLoading) return (
    <MuiThemeProvider theme={theme}>
      <div style={{ margin: 'auto', width: '20%', paddingTop: '20%' }}>
        <CircularProgress color="secondary" />
      </div>
    </MuiThemeProvider>
  )

  console.log('Is auth:' + String(isAuthenticated))
  return (
    <Router>
      <MuiThemeProvider theme={theme}>


        
        <Elements stripe={stripePromise}>
          <Navigation setUserData={setUserData} userData={userData} />
          
              {/* <Authenticate userData={userData} /> */}
          
          <Route path="/authenticate/:aid">
            {isLoggedIn && !isAuthenticated ? <Authenticate userData={userData} setUserData={setUserData} setIsAuthenticated={setIsAuthenticated}/> : null}
          </Route>
          <Route exact path="/">
            <Home userData={userData} />
          </Route>
          <Route exact path="/dashboard">
            {isLoggedIn ? <Dashboard userData={userData} /> : null}
          </Route>
          <Route exact path="/profile">
            {isLoggedIn ? <Profile userData={userData} /> : null}
          </Route>
          <Route exact path="/game-grid">
            {isLoggedIn && isAuthenticated ? <GameGridTable userData={userData} /> : <Redirect to="/authenticate/Enter%20ID%20Code%20Here" />}
          </Route>
          <Route exact path="/game/:id">

            {isLoggedIn && isAuthenticated ? <Game userData={userData} /> :  <Redirect to="/authenticate/Enter%20ID%20Code%20Here" />}
          </Route>
          <Route exact path="/contact-us">
            <ContactUs userData={userData} />
          </Route>
          <Route exact path="/analytics">
            {isLoggedIn && isAuthenticated ? <Analytics userData={userData} /> :  <Redirect to="/authenticate/Enter%20ID%20Code%20Here" />}
          </Route>
          {
            showClaimGames ?

              <Route exact path="/claim-games">

                {isLoggedIn ? <ClaimGames userData={userData} /> : null}
              </Route>
              :
              null
          }

          {
            showAdmin ?
              <Route exact path="/site-admin">

                {isLoggedIn ? <SiteAdmin userData={userData} /> : null}
              </Route>
              :
              null
          }
        </Elements>
      </MuiThemeProvider>
    </Router>
  );
}


