import React, {useState} from 'react'

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js';
import PaymentDialog, {permitAction} from '../../../components/PaymentDialog'


import { Button } from '@material-ui/core'

import firebase, {db} from '../../../utilities/fire'

const stripePromise = loadStripe('pk_test_KdVoCmevUUrlxp5eFGTqsu5V00oJ1oZiBp');

export default function RequestEvaluationButton(props) {

    const [showPayment, setShowPayment] = useState(false)

    function sendEvaluationRequest(){
        db.collection('games').doc(props.rowData.id).update({
            evaluationRequested: true
        })
        db.collection('users').doc(props.userData.uid).update({
            availableEvals: firebase.firestore.FieldValue.increment(-1)
        })
        let newData = props.rowData
        newData.evaluationRequested = true
        props.updateRow(newData, props.rowData, true)
    }

    function handlePaymentComplete() {
        setShowPayment(false)
        sendEvaluationRequest()
    }

    const checkForEvalsPaid = async () => {

        let shouldPermit = await permitAction('requestEval', props.userData)

        if (shouldPermit) {
            sendEvaluationRequest()
        } else {
            setShowPayment(true)
        }

    }



    return (

        <div>

            <Button variant="contained" color="primary" size="small" fullWidth={true} onClick={()=>checkForEvalsPaid()}>
                Request
            </Button>
            {!!showPayment &&
                <Elements stripe={stripePromise}><PaymentDialog userData={props.userData} handlePaymentComplete={handlePaymentComplete} type="requestEval" /></Elements>
            }
        </div>
    )
}