import React, {useState, useEffect} from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import MaterialTable from "material-table";
import {db} from '../../utilities/fire'
import {refereeLevels} from '../../defaults/refereeLevels'
import moment from 'moment'
import CustomLink from '../../components/CustomLink'

export default function ClaimGames(props) {

    const [availableGames, setAvailableGames] = useState([])
    const [claimedGames, setClaimedGames] = useState([])


    function handleClaimGame(rowData) {

        let dueDate = moment.utc().add(14, 'days').unix()

        db.collection('games').doc(rowData.id).update({
            claimedBy: props.userData.uid,
            claimed: true,
            dueDate: dueDate
        }).then(result => {
            
            let newClaims = [...claimedGames]
            newClaims.dueDate = moment(dueDate).format('MM/DD/YYYY')
            newClaims.push(rowData)

            let newAvailable = [...availableGames]
            newAvailable.splice(newAvailable.indexOf(rowData), 1)

            setAvailableGames(newAvailable)
            setClaimedGames(newClaims)

        })


    }

    function ClaimGameButton(rowData) {
        return (
            
                <Button variant="contained" color="primary" size="small" fullWidth={true} onClick={()=>handleClaimGame(rowData)}>Claim Game</Button>
            
        )
    }
// HOLY FUCK. I make this button actually work
    function ViewGameButton(rowData) {
            const gameURL = '/game/' + rowData.id
            let page = {commonName: 'View', url: gameURL}
            return(
            <CustomLink page={page} loggedIn={true} type="Button"/>
            )
    }

    function GetLevel(levelVal) {

        let levelName = ''

        refereeLevels.forEach(level => {
            if (level.value === levelVal) {
                levelName = level.name
            }
        })

        return levelName
    }

    const columnsAvailable = [
        { field: 'homeTeam', title: 'Home Team'},
        { field: 'awayTeam', title: 'Away Team'},
        { field: 'level', title: 'Level'},
        { 
            field: 'claimGame', 
            title: 'Claim This Game',
            render: rowData => ClaimGameButton(rowData)
        },
    ];
    
    const columnsClaimed = [
        { field: 'homeTeam', title: 'Home Team'},
        { field: 'awayTeam', title: 'Away Team'},
        { field: 'level', title: 'Level'},
        { field: 'dueDate', title: 'Due Date'},
        {
            field: 'viewGame', 
            title: 'View Game',
            render: rowData => ViewGameButton(rowData)
        }
    ];
    

    


    useEffect(()=>{

        // Get all games where an evaluation has been requested and where it has not been claimed by an evaluator
        db.collection('games')
            .where('claimed', '==', false)
            .where('evaluationRequested', '==', true)
            .where('level', 'in', props.userData.approvedEvaluationLevels)
            .get().then(query => {

            // If there are available games
            if (query.size > 0) {

                let availableList = []

                query.forEach(game => {

                    if (game.data().claimedBy !== props.userData.uid) {

                        let gameData = game.data()
                    
                        availableList.push({
                            id: game.id,
                            homeTeam: gameData.homeTeam,
                            awayTeam: gameData.awayTeam,
                            level: GetLevel(gameData.level)
    
                        })
                    }
                    
                })

                setAvailableGames(availableList)
            }
        })

        
        // Get all games where a game has been claimed by the evaluator
        db.collection('games').where('claimedBy', '==', props.userData.uid).get().then(query => {

            // If there are available games
            if (query.size > 0) {

                let claimedList = []

                query.forEach(game => {

                    let gameData = game.data()
                
                    claimedList.push({
                        id: game.id,
                        homeTeam: gameData.homeTeam,
                        awayTeam: gameData.awayTeam,
                        level: GetLevel(gameData.level),
                        dueDate: moment(gameData.dueDate).format('MM/DD/YYYY')
                    })
                    
                })

                setClaimedGames(claimedList)
            }
        })

        

    },[props])



    return(
        <Box className="main-wrapper">
            <Grid className="claim-games-container" container direction="row">
                <Grid className="game-table" item xs sm md={6}>
                    <MaterialTable
                        title="Your Claimed Games"
                        columns={columnsClaimed}
                        data={claimedGames}
                        options={{
                            tableLayout: 'fixed',
                            pageSize: 10,
                            pageSizeOptions: [10,20,30],
                        }}/>
                </Grid>
                <Grid className="game-table" item xs sm md={6}>
                    <MaterialTable
                        title="Available Games"
                        columns={columnsAvailable}
                        data={availableGames}
                        options={{
                            tableLayout: 'fixed',
                            pageSize: 10,
                            pageSizeOptions: [10,20,30],
                        }}/>
                </Grid>
            </Grid>
        </Box>
    )
}