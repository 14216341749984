import React, { useState, useCallback } from "react";

import { Button, LinearProgress, CircularProgress} from "@material-ui/core";
import { useDropzone } from "react-dropzone";

import { db, storage } from "../../utilities/fire";

import PaymentDialog, {permitAction} from '../../components/PaymentDialog'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_KdVoCmevUUrlxp5eFGTqsu5V00oJ1oZiBp');


export default function UploadGameButton(props) {
  

  const [progress, updateProgress] = useState(0);
  const [buffer, updateBuffer] = useState(0);
  const [showPayment, setShowPayment] = useState(false)
  const [tmpAcceptedFiles, setTmpAcceptedFiles] = useState(null)
  const [uploadComplete, setUploadComplete] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const onDrop = useCallback(

    async acceptedFiles => {
        async function prelimUpload(acceptedFiles) {

          console.log('testing should upload')
          let showUpload = await permitAction("uploadGame", props.userData);
          if (!showUpload) {
            setShowPayment(true)
            return false
          } else {
            setShowLoading(true)
            return acceptedFiles
          }


        }
        
        if (!tmpAcceptedFiles) {
          
          setTmpAcceptedFiles(acceptedFiles)
        }
        
        let uploadFiles = await prelimUpload(acceptedFiles)

        if (uploadFiles) {

          const upload = storage.ref();

          const path = "games/" + props.gameData.id + "/" + uploadFiles[0].name;
          const fileToUpload = upload.child(path).put(uploadFiles[0]);
      
            fileToUpload.on(
              "state_changed",
              snapshot => {
                const progress = Math.round(
                  (snapshot.bytesTransferred * 100) / snapshot.totalBytes
                );
                updateProgress(progress);
                updateBuffer(progress + Math.random() * 10);
              },
              error => {
                console.log(error);
              },
              () => {
                fileToUpload.snapshot.ref.getDownloadURL().then(url => {

                  if (!uploadComplete) {
                    console.log('is the upload complete?')
                    console.log(uploadComplete)
                    console.log('The progress item')
                    console.log(progress)
                    db.collection('users').doc(props.userData.uid).get().then(doc => {
                      db.collection('users').doc(props.userData.uid).update({ availableHalves: doc.data().availableHalves - 2 })

                    })
                  }
                  setUploadComplete(true)
                  db.collection("games")
                    .doc(props.gameData.id)
                    .update({
                      uploadAuthor: props.userData.uid,
                      originalFileName: uploadFiles[0].name,
                      filmUpload: url,
                      claimed: false
                    })
                    .then(result => {
                      let newTableData = props.gameData;
                      newTableData.filmUpload = url;
                      props.updateRow(newTableData, props.gameData, true);
                    });
                });
                updateProgress(0);
                updateBuffer(0);
                setTmpAcceptedFiles(null)

              }
            );
        }
    },
    [props, tmpAcceptedFiles, progress, uploadComplete]
  );

  function handlePaymentComplete() {
    setShowPayment(false)
    onDrop(tmpAcceptedFiles)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });



  return (
    <div>
      <div {...getRootProps()}>
        <input type="file" {...getInputProps()} style={{ display: "none" }} />

        {isDragActive ? (
            <div>
          {progress === 0 ? <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth={true}
          >
            Drop
          </Button> : null}

            </div>
        ) : (
            <div>
                {progress === 0 ? 
                
                  <div>
                    {showLoading ? 
                    <CircularProgress color="secondary" />
                    :
                    
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      fullWidth={true}
                    >
                      Upload
                    </Button>
                    }
                  </div>
                : null}
                </div>
        )}

        {progress ? (
          <LinearProgress
            variant="determinate"
            value={progress}
            valueBuffer={buffer}
            color="secondary"
          />
        ) : null}
        
      </div>
      {!!showPayment && 
        <Elements stripe={stripePromise}><PaymentDialog userData={props.userData} handlePaymentComplete={handlePaymentComplete} type="uploadGame" /></Elements>
      }
    </div>
  );
}
