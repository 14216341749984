import React, {useState, useEffect} from 'react';
import {Grid, Box, Select, Input, FormControl, MenuItem, Checkbox, ListItemText, Button, Dialog, 
    DialogActions, DialogContent, DialogTitle, DialogContentText, TextField, InputLabel} from '@material-ui/core';
import MaterialTable from "material-table";
import {db} from '../../utilities/fire';

import {refereeLevels} from '../../defaults/refereeLevels'

export default function SiteAdmin(props) {

    const [userBase, setUserBase] = useState([])
    const [showDialog, setShowDialog] = useState(false)
    const [clientEditData, setClientEditData] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [evalLevels, setEvalLevels] = useState([])

    useEffect(()=> {

        db.collection('users').orderBy('lastName', 'asc').get().then(query => {

            if (query.size > 0) {

                let newUserBase = []

                query.forEach(user => {

                    let userData = user.data()
                    newUserBase.push({
                        id: user.id,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        email: userData.email, 
                        approvedEvaluationLevels: userData.approvedEvaluationLevels
                    })
                })

                setUserBase(newUserBase)
                setIsLoaded(true)
            }
        })
    }, [isLoaded])

    const columns = [
        {
            title: 'First Name',
            field: 'firstName',
        },
        {
            title: 'Last Name',
            field: 'lastName',
        },
        {
            title: 'Email',
            field: 'email',
        },
        {
            title: 'Permissions',
            field: 'permissions',
            render: rowData => <Button color="primary" variant="contained" size="small" onClick={()=>handleChangePermissions(rowData)}>Edit</Button>
        }
    ]

    const ITEM_HEIGHT = 90;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };

    function handleChangePermissions(rowData) {

        setClientEditData(rowData)

        if (!!rowData.approvedEvaluationLevels) {

            setEvalLevels(rowData.approvedEvaluationLevels)
        } 
        console.log(rowData)
        setShowDialog(true)


    }

    const handleChangeMultipleLevels = event => {

        db.collection('users').doc(clientEditData.id).update({
            approvedEvaluationLevels: event.target.value
        })
        let newClientData = clientEditData
        newClientData.approvedEvaluationLevels = event.target.value
        setEvalLevels(event.target.value)
    }

    function handleClose() {
        setClientEditData({})
        setEvalLevels([])
        setShowDialog(false)
    }

    function EditCheckBox(level) {

        let isChecked = false
        // {clientEditData.approvedEvaluationLevels ? 
        //     <Checkbox checked={clientEditData.approvedEvaluationLevels.indexOf(level.value) > -1} />
        //   : null}

        if (evalLevels) {
            if (evalLevels.indexOf(level.value) > -1) {
                isChecked = true
            }
        }

          return <Checkbox checked={isChecked} />
    }

    return(
       
        <Box className="main-wrapper">
            <Dialog open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit User Permissions</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Site Administrators May Edit User Data Here
                        <br />
                    </DialogContentText>
                    <TextField
                        
                        margin="dense"
                        id="name"
                        label="First name"
                        type="text"
                        fullWidth
                        value={clientEditData.firstName}
                    />
                    <TextField
                        
                        margin="dense"
                        id="name"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={clientEditData.lastName}
                    />
                    <TextField
                        disabled
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={clientEditData.email}
                    />
                    <FormControl>
                        <InputLabel id="EvalLevel">Evaluator Level</InputLabel>
                        <Select
                            multiple
                            value={evalLevels}
                            onChange={handleChangeMultipleLevels}
                            input={<Input />}
                            renderValue={selected => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                            {refereeLevels.map(level => (
                              <MenuItem key={level.name} value={level.value}>
                                  {EditCheckBox(level)}
                                <ListItemText primary={level.name} />
                              </MenuItem>
                            ))}
                          </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid className="site-admin-container" container>
                <Grid className="people-table" item xs sm md={8}>
                    <MaterialTable
                        title="Site Administration"
                        columns={columns}
                        data={userBase}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [10,20,50],
                        }}
                    >

                    </MaterialTable>
                </Grid>
            </Grid>
        </Box>
    );
}