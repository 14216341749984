import React, {useState, useEffect} from 'react'
import { useParams, withRouter, Redirect } from "react-router-dom";
import {Typography, Input, Box, Button} from '@material-ui/core'

import {db} from '../../utilities/fire'

function Authenticate(props) {

    const { aid } = useParams();
    const [code, setCode] = useState(aid)
    const [confirmed, setConfirmed] = useState(false)


    function handleConfirm() {

        db.collection('users').doc(props.userData.uid).get().then(doc => {

            if (doc.data().confirmedEmail === code) {

                db.collection('users').doc(props.userData.uid).set({
                    confirmedEmail: 'confirmed'
                },{merge: true}).then(async result => {

                    console.log('Hellow here')
                    let newUserData = props.userData
                    newUserData.confirmedEmail = 'confirmed'
                    // eslint-disable-next-line no-unused-vars
                    const updateUserData = await props.setUserData(newUserData)
                    const updateConfirm = await setConfirmed(true)
                    const updateAuth = await props.setIsAuthenticated(true)
                    props.history.push('/game-grid')
                    
                })
            } else {
                alert('That is not the right code.')
            }
        })
        // props.history.push('/game-grid')

    }

    // function handleChangeConfirm() {
    //     setConfirmed(true)
    // }

    return (
        <Box className="main-wrapper">
            {confirmed ? 
            
                <div>
                    You are confirmed. Click here to get started.
                    <a href="/game-grid">Let's go!</a>
                </div>
            
            :
                <div>

                    <Typography variant="h4">Enter your code here</Typography>
                    <Input
                        value={code}
                        onChange={e => setCode(e.target.value)}
                    ></Input>
                    <br />
                    <Button variant="contained" color="secondary" onClick={() => handleConfirm()}>Confirm Email</Button>
                </div>}
        </Box>
    )
}

export default withRouter(Authenticate);