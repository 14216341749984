import React, {useState, useEffect} from 'react';
import { Grid, Typography, TextField,
    Box, Card, CardContent, CardActions, Button,
    Snackbar, Link} 
    from '@material-ui/core';
import {db, auth} from '../../utilities/fire';
import { Alert } from '@material-ui/lab'; 
import 'intro.js/introjs.css';
import CustomLink from '../../components/CustomLink'

export default function Home(props) {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    useEffect(()=>{
        
        if (props.userData) {

            if (props.userData.uid) {
                
                db.collection('users').doc(auth.currentUser.uid).get().then(doc => {
                  if (!!doc.data().firstName) {
                    setFirstName(doc.data().firstName)
                  }
                  if (!!doc.data().lastName) {
                    setLastName(doc.data().lastName)
                  }
                  if (!!doc.data().email) {
                    setEmail(doc.data().email)
                  }
                })

            }

        }
        

    }, [props.userData])


    const handleSave = () => {
      db.collection('users').doc(auth.currentUser.uid).set({
        firstName: firstName,
        lastName: lastName,
        email: email,
      }, {merge: true})
      setOpen(true);
    }
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    
    const superClick = () => {
        handleClick();
        handleSave();
    } 
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

      const steps = [
        {
          element: '.text',
          intro: 'Welcome to Referee Datalytics!',
          position: 'right',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.another',
          intro: 'This is another prompt, sucka.',
        },
      ];

    //   const options = {
    //     showProgress: true,
    //     keyboardNavigation: true,
    //     showBullets: false,

    //   };
    
    // const onExit = () => {
    //     setStepsEnabled(false);
    // }
    // const toggleSteps = () => {
    //     setStepsEnabled(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
    // };

    return (
        
            <Box className="main-wrapper">
                <Grid className="marketing-space" >
                    
                        <img alt="RefLytics Logo" src={require('../../images/refdatasquare.png')}/>
                    <Grid item xs={12}>
                        <Typography className="welcome-message" variant="h2">Real, Honest, Direct Feedback.</Typography>
                    </Grid>
                </Grid>
                <Grid className="info-grid" container direction="row">
                    <Grid className="info-grid-item" item xs={10} sm={10} md={3}>
                        <Typography className="info-title" variant="h4">The product.</Typography>
                        <Card className="info-card" variant="outlined">
                            <CardContent>
                                <Typography className="info-card-title" gutterBottom variant="h6">What is Referee Datalytics?</Typography>
                                <Typography className="info-card-text">
                                    RefLytics is the solution for <u><b>YOUR</b></u> development as an official. 
                                    Personalized evaluations with a data driven approach will push yourself to the next level.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className="info-card" variant="outlined">
                            <CardContent>
                                <Typography className="info-card-title" gutterBottom variant="h6">Who are we?</Typography>
                                <Typography className="info-card-text" gutterBottom>
                                    Are you a high school official wanting to work college?<br/><br/>
                                    How about a small college official working to get more games?<br/><br/>
                                    Or even an offical with NBA aspirations?
                                </Typography>
                                <Typography className="info-card-text">
                                    RefLytics has a team of dedicated officials from the level you want feedback from.
                                    Certified officials ranging from High School, to NCAA Division I, to the NBA, are here to give you the feedback you deserve.                            
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <CustomLink page={{
                                            commonName: 'Upload Your Games',
                                            url: '/game-grid',
                                            alwaysShow: true
                                            }} loggedIn={true} type={'Button'}/>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid className="info-grid-item" item xs={10} sm={10} md={3}>                          
                        <Card className="info-card" variant="outlined">
                            <CardContent>
                                <Typography className="info-card-title" gutterBottom variant="h6">Create a login to access all features!</Typography><br></br>
                                <TextField className="text-field" required variant="outlined" label="First Name" fullWidth value={firstName} onChange={(event) => setFirstName(event.target.value)}/><br/>
                                <TextField className="text-field" required variant="outlined" label="Last Name" fullWidth value={lastName} onChange={(event) => setLastName(event.target.value)}/><br/>
                                <TextField className="text-field" required variant="outlined" label="Email" type="email" fullWidth value={email} onChange={(event) => setEmail(event.target.value)}/>
                            </CardContent>
                            <CardActions>
                                    <Button color="secondary" variant="contained" type="submit" form="form" onClick={superClick} fullWidth={true}>Sign Up!</Button>
                                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                    <Alert onClose={handleClose} severity="success">Success!</Alert>
                                    </Snackbar>
                            </CardActions>
                        </Card> 
                    </Grid>
                    <Grid className="info-grid-item" item xs={10} sm={10} md={3}>
                        <Typography className="info-title" variant="h4">The features.</Typography>
                            <Card className="info-card" variant="outlined">
                                <CardContent>
                                    <Typography className="info-card-title" gutterBottom variant="h6">Evaluation</Typography>
                                    <Typography className="info-card-text" gutterBottom>
                                        It's this simple:
                                    </Typography>
                                    <Typography className="info-card-text" gutterBottom>
                                        1) Upload your game film<br/>
                                        2) Request your evaluator<br/>
                                        3) Receive a detailed breakdown<br/>
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <CustomLink page={{
                                                    commonName: 'Choose Your Evaluators',
                                                    url: '/profile',
                                                    alwaysShow: true
                                                    }} loggedIn={true} type={'Button'}/>
                                </CardActions>
                            </Card>
                            <Card className="info-card" variant="outlined">
                                <CardContent>
                                    <Typography className="info-card-title" gutterBottom variant="h6">Anonymity</Typography>
                                    <Typography className="info-card-text" gutterBottom>
                                        The best feedback is:
                                    </Typography>
                                    <Typography className="info-card-text" gutterBottom>
                                        1) Honest<br></br>
                                        2) Real<br></br>
                                        3) Constructive<br></br>
                                        4) Pointed
                                    </Typography>
                                    <Typography className="info-card-text">
                                        And we promise to bring all of that to you. Our evaluators must stay anonymous for this to work.
                                    </Typography>
                                </CardContent>
                            </Card>  
                    </Grid>
                </Grid>
            </Box>
    );
}