import React, {useState, useEffect} from 'react';
import {db} from '../../utilities/fire';
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import {Box, Grid, Tab, AppBar, Tabs} from '@material-ui/core';
import { Line, Bar} from 'react-chartjs-2'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={1}>{children}</Box>}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function Analytics(props) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      }
    const columns = [
        { 
            title: 'Home Team', 
            field: 'hometeam' 
        },
        { 
            title: 'Away Team', 
            field: 'awayteam' 
        },
        { 
            title: 'Location', 
            field: 'location' 
        },
        { 
            title: 'Attendance', 
            field: 'attendance' 
        },
        { 
            title: 'Date & Time', 
            field: 'datetime' 
        },
        { 
            title: 'Official 1', 
            field: 'o1' 
        },
        { 
            title: 'Official 2', 
            field: 'o2' 
        },
        { 
            title: 'Official 3', 
            field: 'o3' 
        },
    ]
    const columnsRefs = [
        { 
            title: 'Official', 
            field: 'o1' 
        },
        { 
            title: 'Game Count', 
            field: 'gameCount' 
        },
    ]
    
    const [gameData, setGameData] = useState([])
    const [refData, setRefData] = useState([])

    useEffect(() => {
        db.collection('data').where('successful', '==', true).limit(10).get().then(query =>{
            if (query.size > 0) {
                let gameList = []
                console.log(query.size + 'number of games.')
                query.forEach(game => {
                    if (game.data().successful !== false) {
                        let gameData = game.data()
                        gameList.push({ 
                            hometeam: gameData.hometeam,
                            awayteam: gameData.awayteam,
                            location: gameData.location, 
                            attendance: gameData.attendance, 
                            datetime: gameData.datetime, 
                            o1: gameData.o1,
                            o2: gameData.o2,
                            o3: gameData.o3,
                        })
                    }
                })
                setGameData(gameList)
            }
        })
        db.collection('data').limit(10).get().then(query =>{
            if (query.size > 0) {
                let refList = []
                console.log(query.size + 'number of games.')
                query.forEach(game => {
                    if (game.data().successful !== false) {
                        let gameData = game.data()
                        refList.push({ 
                            o1: gameData.o1,
                            
                        })
                    }
                })
                setRefData(refList)
            }
        })
    }, [props])

    const data = {
        labels: ['Correct Calls', 'Incorrect Calls', 'No Call Corrects', 'No Call Incorrects'],
        datasets: [
          {
            label: 'My First dataset',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 2,
            hoverBackgroundColor: 'rgba(150,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [90,13,85,20]
          }
        ]
      };
    
    return (
        <div className="main-wrapper">
            <AppBar className="appbar">
                <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Analytics" icon={''} {...a11yProps(0)} />
                <Tab label="Games" {...a11yProps(1)} />
                <Tab label="Charts" {...a11yProps(2)} />
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
                <Grid className="two-app-bar-container" container>
                    <Grid item xs sm={6}>
                    
                    </Grid>
                </Grid>    
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Grid className="two-app-bar-container" container>
                    <Grid className="game-table" item xs sm md={4}>
                        <MaterialTable
                            className="referee-table1"
                            title="Referee Data"
                            columns={columnsRefs}
                            data={refData}
                            options={{
                                pageSize: 10,
                                pageSizeOptions: [50,100,200],
                            }}
                            detailPanel={rowData => {
                                return (
                                  <iframe
                                    title="a"
                                    width="100%"
                                    height="315"
                                    src="https://www.youtube.com/embed/C0DPdy98e4c"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                  />
                                )
                              }}
                              onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        />
                    </Grid>
                    <Grid className="game-table" item xs sm md={8}>
                        <MaterialTable
                            title="Game Data"
                            columns={columns}
                            data={gameData}
                            options={{
                                pageSize: 10,
                                pageSizeOptions: [50,100,200],
                            }}
                        />
                    </Grid>
                </Grid>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Grid className="two-app-bar-container" container>
                    <Grid item xs sm md={6}>
                        <Bar data={data}/>
                    </Grid>
                    <Grid item xs sm md={6}>
                        <Line data={data}/>
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    );
}