import React, { useState } from 'react';
import {db} from '../../utilities/fire';
import {
    Box, Snackbar, Typography, Grid, TextField, Button
} from '@material-ui/core'
import { Alert } from '@material-ui/lab';

// const Handlebars = require("handlebars");

export default function ContactUs(props) {

    const [name, setName] = useState(props.userData.firstName ? props.userData.firstName + ' ' + props.userData.lastName : '')
    const [email, setEmail] = useState(props.userData.email ? props.userData.email : '')
    const [inquiry, setInquiry] = useState('')

    function handleSubmitEmail() {

        db.collection('mail').add({
            to: email,
            template: {
                name: 'ContactUs',
                data: {
                    name: name,
                    inquiry: inquiry
                }
            }
        }).then(result => {
            setInquiry('')
        })
    }

    function CheckInputs() {
        if (
            name && email && inquiry
        ) {
            return false
        } else {
            return true
        }
    }

    const [open, setOpen] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
   
    
    return(
            <Box className="main-wrapper">
                <Grid className="contact-us-container" container>
                    <Grid className="forms" item xs sm={6}>
                        <Typography className="forms-title" align="center" variant="h4">
                            Contact RefLytics
                        </Typography><br/>
                        <TextField className="text-field" id="name" label="Name" variant="outlined" value={name} onChange={e => setName(e.target.value)} /><br/>
                        <TextField className="text-field" id="inquiry" label="Question or comment" variant="outlined" multiline rows="10" value={inquiry} onChange={e => setInquiry(e.target.value)}  /><br/>
                        <TextField className="text-field" id="email" label="Email" variant="outlined" value={email} onChange={e => setEmail(e.target.value)} /><br/>

                        <Button color="primary" fullWidth={true} variant="contained" onClick={()=>handleSubmitEmail()} disabled={CheckInputs()}>
                            Submit    
                        </Button>
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success">Success!</Alert>
                        </Snackbar>
                        
                    </Grid>
                </Grid> 
            </Box>        
    );

    
}