import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, createMuiTheme,  Button, Typography, Slider,  TextField, Input } from '@material-ui/core'

import { Theme } from "../../../styles/Theming";
import { db } from "../../../utilities/fire";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';



export default function FinishEvaluation(props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(50);
  const [seeCheck, setSeeCheck] = useState(false);
  
  const [presence, setPresence] = useState(50);
  const [communication, setCommunication] = useState(25);
  const [appearance, setAppearance] = useState(50);
  const [signals, setSignals] = useState(35);
  const [consistency, setConsistency] = useState(40);
  const [management, setManagement] = useState(45);
  const [notes, setNotes] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

    useEffect(() => {
        db.collection("evaluations")
        .doc(id)
        .get()
        .then(doc => {
            // This is good! Check the thing exists before you reference it
            if (doc.exists) {
                // This is good! check what you want is there
                if (!!doc.data().final) {
                    setAppearance(doc.data().final.appearance)
                    setPresence(doc.data().final.presence)
                    setCommunication(doc.data().final.communication)
                    setSignals(doc.data().final.signals)
                    setConsistency(doc.data().final.consistency)
                    setManagement(doc.data().final.management)
                    setNotes(doc.data().final.notes)
                }
            }
        });
    }, [isLoading, id]);

  const handleSave = () => {
    db.collection("evaluations").doc(id).set({final: {
        presence: presence,
        communication: communication,   
        appearance: appearance,
        signals: signals,
        consistency: consistency,
        management: management,
        notes: notes,
    }}, {merge: true})
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
   useEffect(() => {
    db.collection("evaluations").doc(id).get().then(doc => {
            if (doc.exists) {
            if ( doc.data().final != null) {
                setSeeCheck(true);
            }
            }
        });
    }, [props, id]);

  const superClick = () => {
    handleClose();
    handleSave();
  }

  const handlePresenceChange = (event, newValue) => {setPresence(newValue);};
  const handlePresence2Change = (event) => {setPresence(event.target.value === '' ? '' : Number(event.target.value));};
  const handlePresenceBlur = () => {if (value < 0) {setPresence(0);} else if (value > 100) {setPresence(100);}};
  const handleCommunicationChange = (event, newValue) => {setCommunication(newValue);};
  const handleCommunication2Change = (event) => {setCommunication(event.target.value === '' ? '' : Number(event.target.value));};
  const handleCommunicationBlur = () => {if (value < 0) {setCommunication(0);} else if (value > 100) {setCommunication(100);}};
  const handleAppearanceChange = (event, newValue) => {setAppearance(newValue);};
  const handleAppearance2Change = (event) => {setAppearance(event.target.value === '' ? '' : Number(event.target.value));};
  const handleAppearanceBlur = () => {if (value < 0) {setAppearance(0);} else if (value > 100) {setAppearance(100);}};
  const handleSignalsChange = (event, newValue) => {setSignals(newValue);};
  const handleSignals2Change = (event) => {setSignals(event.target.value === '' ? '' : Number(event.target.value));};
  const handleSignalsBlur = () => {if (value < 0) {setSignals(0);} else if (value > 100) {setSignals(100);}};
  const handleConsistencyChange = (event, newValue) => {setConsistency(newValue);};
  const handleConsistency2Change = (event) => {setConsistency(event.target.value === '' ? '' : Number(event.target.value));};
  const handleConsistencyBlur = () => {if (value < 0) {setConsistency(0);} else if (value > 100) {setConsistency(100);}};
  const handleManagementChange = (event, newValue) => {setManagement(newValue);};
  const handleManagement2Change = (event) => {setManagement(event.target.value === '' ? '' : Number(event.target.value));};
  const handleManagementBlur = () => {if (value < 0) {setManagement(0);} else if (value > 100) {setManagement(100);}};

  const body = (
    <div>
        
            <Typography id="simple-modal-title" variant="h5">Finish Your Evaluation</Typography>
            <Typography id="simple-modal-description" variant="body1">Complete the following summary information for the client:</Typography><br/>     
            
            <Typography variant="h6">Presence</Typography><Typography variant="body2">(Court Presence, Posture, Sense of Control)</Typography>
            <Slider 
                value={presence}
                onChange={handlePresenceChange}
                aria-labelledby="input-slider"
            />            
            <Input
                value={presence}
                onChange={handlePresence2Change}
                onBlur={handlePresenceBlur}
                inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
                disableUnderline={true}
            /><br/>

            <Typography variant="h6">Communication</Typography><Typography variant="body2">(Partners, Coaches, Players)</Typography>
            <Slider 
                value={communication}
                onChange={handleCommunicationChange}
                aria-labelledby="input-slider"
            />            
            <Input
                value={communication}
                onChange={handleCommunication2Change}
                onBlur={handleCommunicationBlur}
                inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
                disableUnderline={true}
            /><br/>

            <Typography variant="h6">Appearance</Typography>
            <Typography variant="body2">(Fitness, Clothing, Style)</Typography>
            <Slider 
                value={appearance}
                onChange={handleAppearanceChange}
                aria-labelledby="input-slider"
            />            
            <Input
                value={appearance}
                onChange={handleAppearance2Change}
                onBlur={handleAppearanceBlur}
                inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
                disableUnderline={true}
            /><br/>

            <Typography variant="h6">Signals</Typography><Typography variant="body2">(Proper Use, Strength, Clear)</Typography>
            <Slider 
                value={signals}
                onChange={handleSignalsChange}
                aria-labelledby="input-slider"
            />            
            <Input
                value={signals}
                
                onChange={handleSignals2Change}
                onBlur={handleSignalsBlur}
                inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
                disableUnderline={true}
            /><br/>

            <Typography variant="h6">Consistency</Typography><Typography variant="body2">(Crew/Personal Call Consistency)</Typography>
            <Slider 
                value={signals}
                onChange={handleConsistencyChange}
                aria-labelledby="input-slider"
            />            
            <Input
                value={consistency}
                
                onChange={handleConsistency2Change}
                onBlur={handleConsistencyBlur}
                inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
                disableUnderline={true}
            /><br/>

            <Typography variant="h6">Game Management</Typography><Typography variant="body2">(Bench Decorum, Conflict Management)</Typography>
            <Slider 
                value={management}
                onChange={handleManagementChange}
                aria-labelledby="input-slider"
            />            
            <Input
                value={management}
                
                onChange={handleManagement2Change}
                onBlur={handleManagementBlur}
                inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
                }}
                disableUnderline={true}
            /><br/>
            
            <Typography variant="h6">Final Comments</Typography>
            <TextField
                autoFocus
                value={notes}
                onChange={event => setNotes(event.target.value)}
                multiline
                variant="outlined"
                rows="6"
                fullWidth   
                style={{margin: '0 0 10px 0'}}
            />
            <Button variant="contained" color="secondary" fullWidth={true} onClick={superClick}>COMPLETE</Button>
    </div>
  );

  return (
    <div>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        Finish Evaluation
      </Button>
        {
            seeCheck ?
            <div>
                <CheckCircleIcon/> Evaluation Submitted
            </div>
            : null
        }   
      <Modal
        className="finish-evaluation"
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}