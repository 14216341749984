import firebase from 'firebase'


  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "ref-tech-by-sbm.firebaseapp.com",
    databaseURL: "https://ref-tech-by-sbm.firebaseio.com",
    projectId: "ref-tech-by-sbm",
    storageBucket: "ref-tech-by-sbm.appspot.com",
    messagingSenderId: "557364352879",
    appId: "1:557364352879:web:23ac07516a44732aee3a9a",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  firebase.initializeApp(firebaseConfig);

  export const provider = new firebase.auth.EmailAuthProvider();
  export const auth = firebase.auth()
  export const db = firebase.firestore()
  export const storage = firebase.storage()
  export const storageRef = storage.ref()
  


  export default firebase;